/* src/styles.css */
.app {
  text-align: center;
}

/* Add more styles as needed */
:root {
  --header-text: rgba(253, 242, 221, 1);
  --main-text: rgba(59, 34, 21, 1);
  --teal: rgba(80, 175, 149, 1);
  --blue: rgba(1, 154, 240, 1);
  --white: rgba(255, 255, 255, 1);
  --white08: rgba(255, 255, 255, 0.08);
  --nav-text-inactive: rgba(83, 34, 9, 1);

  --gold: rgba(233, 184, 44, 1);
  --silver: rgba(190, 205, 222, 1);
  --bronze: rgba(211, 136, 78, 1);
  --lowRating: rgba(158, 178, 203, 1);

  --about-gold: rgba(255, 202, 61, 1);
  --about-blue: rgb(0, 174, 239);
  --about-bronze: rgba(215, 153, 43, 1);

  --text-shadow1: rgba(122, 52, 0, 1);
  --text-shadow2: rgba(249, 150, 53, 0.5);
  --text-shadow3: rgba(249, 150, 53, 0.1);

  --blue-sky-bg: rgba(154, 195, 232, 1);
  --green-grass-bg: rgba(87, 158, 61, 1);
  --green-ligth-bg: rgba(0, 227, 0, 1);

  --gradient-top: rgba(255, 235, 19, 1);
  --gradient-bot: rgba(242, 153, 17, 1);

  --red: rgba(255, 0, 0, 1);
  --dark-red: rgba(159, 8, 11, 1);
  --black: rgba(0, 0, 0, 1);
  --orange: rgba(255, 100, 26, 1);
}

img {
  display: block;
  height: auto;
}

* {
  margin: 0;
  padding: 0;
  text-decoration: none;
}

ul,
ol {
  list-style: none;
}

.custom-toast-success {
  background-color: #ede8d8 !important;
  font-family: "Lilita One" !important;
  font-size: 1.25rem !important;
  color: var(--main-text) !important;
}

.custom-progress {
  background: linear-gradient(to right, #4caf50, #8bc34a) !important;
}