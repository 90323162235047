.container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: space-between; */
    justify-content: center;

    /* background-image: url("/public/images/modalTasksContent/base.webp"); */
    background-image: url("/public/images/channel_base.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    width: 95.28vw;
    aspect-ratio: 343 / 254;
    height: auto;

    gap: 10px;
}

.btnClose {
    position: absolute;
    top: -1.21vh;
    right: 0;
}

.planeImg {
    position: absolute;
    top: -13vh;

    left: 50%;
    transform: translateX(-50%);

    width: 62.5vw;
    aspect-ratio: 225 / 140;
    height: auto;
}

.textTitle {
    margin-top: 4vh;

    font-size: 1.25rem;
    color: var(--about-blue);

    text-shadow:
        1px 1px 1px #000,
        /* Тень справа снизу */
        -1px 1px 1px #000,
        /* Тень слева снизу */
        -0.5px -0.5px 0.5px #000,
        /* Тень слева сверху */
        0.5px -0.5px 0.5px #000;
    /* Тень справа сверху */
}

.textBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
}

.text {
    text-transform: uppercase;
    font-size: 1rem;
    color: var(--header-text);
    text-shadow: -1px -1px 0 #000,
        1px -1px 0 #000,
        0 2px 0 #000,
        1px 1px 0 #000;
}

.textBoxNote {
    display: flex;
    flex-direction: column;
    width: 80vw;
    text-align: center;
    gap: 4px;

}

.textNote {
    text-transform: uppercase;
    font-size: 0.55rem;
    color: var(--red);
    /* color: var(--orange); */
    text-shadow: -1px -1px 0 #000,
        1px -1px 0 #000,
        0 2px 0 #000,
        1px 1px 0 #000;
}

.input {
    font-size: 0.6rem;
    color: var(--main-text);
    text-transform: uppercase;
    text-align: center;

    background-image: url("/public/images/input_bg.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;

    border: none;
    outline: none;

    width: 80.83vw;
    aspect-ratio: 291 / 44;
    height: auto;
}

.input::placeholder {
    color: var(--main-text);
    opacity: 0.5;
}

@keyframes clickAnimation {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(0.9);
    }

    100% {
        transform: scale(1);
    }
}

.btn {
    position: absolute;
    bottom: -2vh;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    background-color: transparent;
    transition: transform 0.1s;

    width: 45.83vw;
    aspect-ratio: 165 / 40;
    height: auto;
    pointer-events: visible;
}

.btn:active {
    animation: clickAnimation 0.2s forwards;
}

@media screen and (min-height: 740px) {
    .planeImg {
        top: -9vh;
    }
}