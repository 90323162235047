.container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    padding-top: 64vh;
    padding-left: 4vw;
    padding-right: 4vw;

    background-size: contain;

    background-repeat: no-repeat;
    background-position: top center;
    background-attachment: fixed;

    width: 100vw;
    height: 100vh;

    gap: 24px;
}

.listContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.listContainerUp {
    width: 84vw;
}

.listContainerDown {
    width: 90vw;
}

.barContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90vw;

    gap: 8px;
}

.textBox {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
}


.itemContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 20vw;
}

.itemBG {
    position: relative;
    display: flex;
    justify-content: center;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    margin-bottom: 3vh;

    width: 17.78vw;
    aspect-ratio: 64 / 72;
    height: auto;
}

.itemBGTop {
    width: 17.78vw;
    aspect-ratio: 64 / 72;
    height: auto;
}

.itemBGBot {
    width: 13vw;
    aspect-ratio: 47 / 53;
    height: auto;
}

.title {
    font-size: 0.75rem;
    text-transform: uppercase;
    color: var(--header-text);
    white-space: nowrap;
    /* запрещает перенос строки */

    text-shadow: rgb(0, 0, 0) -1px -1px 0px, rgb(0, 0, 0) 1px -1px 0px, rgb(0, 0, 0) 0px 2px 0px, rgb(0, 0, 0) 1px 1px 0px;
}

.screenBtnText {
    font-size: 1.25rem;
    text-transform: uppercase;
    color: var(--header-text);
    text-shadow: rgb(0, 0, 0) -1px -1px 0px,
        rgb(0, 0, 0) 1px -1px 0px,
        rgb(0, 0, 0) 0px 3px 0px,
        rgb(0, 0, 0) 1px 1px 0px
}

.text {
    font-size: 0.65rem;
    color: var(--header-text);
    white-space: nowrap;
    /* запрещает перенос строки */

    text-shadow:
        0.5px 0.5px 0.5px #000,
        -0.5px 0.5px 0.5px #000,
        -0.5px -0.5px 0.5px #000,
        0.5px -0.5px 0.5px #000;
}

.textBlue {
    color: var(--blue);
}

.coinImg {
    width: 5vw;
    aspect-ratio: 18 / 18;
    height: auto;
}

@keyframes clickAnimation {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(0.9);
    }

    100% {
        transform: scale(1);
    }
}

.btn {
    position: absolute;
    bottom: -2vh;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    background-color: transparent;
    transition: transform 0.1s;

    pointer-events: visible;
}

.btnScreen {
    position: absolute;
    bottom: 68vw;
    left: 50%;
    transform: translateX(-50%);

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    border: none;
    background-color: transparent;
    transition: transform 0.1s;

    pointer-events: visible;
    width: 54.44vw;
    aspect-ratio: 196 / 47;
    height: auto;
}

.btnScreen:disabled {
    cursor: not-allowed;
}

.enabled {
    background-image: url("/public/images/frens/invite_friends_btn.webp");
}

.disabled {
    pointer-events: none;
    background-image: url("/public/images/startTasks/btn_grey.webp");
}

.btnSizeTop {
    width: 22.78vw;
    aspect-ratio: 82 / 32;
    height: auto;
}

.btnSizeBot {
    width: 16.67vw;
    aspect-ratio: 60 / 23;
    height: auto;
}

.btn:active,
.btnScreen:active {
    animation: clickAnimation 0.2s forwards;
}

@media screen and (min-height: 665px) {
    .btnScreen {
        position: static;
        transform: none;
        bottom: auto;
        left: auto;
        margin: 0 auto;
    }
}

/* ====== PROGRESS BAR ============= */
.progressContainer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("/public/images/startTasks/bar_bg_base.webp");
    border: none;
    background-color: transparent;

    pointer-events: visible;
    width: 86.11vw;
    aspect-ratio: 310 / 47;
    height: auto;
}

.progressBar {
    display: flex;
    justify-content: center;
}

.progressBarStroke {
    position: absolute;
    top: 2.5vh;
    background-color: #38D4FF;
    opacity: 25%;

    width: 72.78vw;
    aspect-ratio: 262 / 3;
    height: auto;
}

.coinImgBar {
    width: 4.44vw;
    aspect-ratio: 16 / 16;
    height: auto;
}

.segment {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #1773A7;
    background-color: #0F3449;
    transition: background-color 0.3s;

    width: 10.5vw;
    aspect-ratio: 41 / 23;
    height: auto;
}

.completed {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #1773A7;
    background-color: #27A4EA;
    transition: background-color 0.3s;

    width: 10.5vw;
    aspect-ratio: 41 / 23;
    height: auto;
}

.firstSegment {
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
}

.lastSegment {
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
}