.list {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 0 4.44vw;
    width: 91.11vw;
    height: 53vh;
    max-height: 53vh;

    overflow-y: auto;
    pointer-events: visible;
    overflow-x: hidden;
    /* margin-bottom: 5vh; */
}

@media screen and (min-height: 750px) {
    .list {
        height: 57vh;
        max-height: 57vh;
    }
}

.listItemBox {
    position: relative;
}

/* CURRENT PLAYER =================  */
.currentPlayerContainer {
    position: fixed;
    bottom: 10vh;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    z-index: 2;
    width: 100vw;
}

.listItemCP {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.89vh 5vw 0.89vh 2.9vw;

    background-image: url("/public/images/frens/layer.webp");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    width: 91.11vw;
    aspect-ratio: 360 / 58;
    height: auto;
}

/* LIST ITEM =================  */
@keyframes fadeInFromTop {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.listItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.89vh 4.9vw 0.89vh 2.9vw;

    background-image: url("/public/images/frens/layer.webp");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(70%);
        transform: translateX(-50%);
    }

    to {
        opacity: 1;
        transform: translateY(90%);
        transform: translateX(-50%);
    }
}

.ropesBG {
    position: absolute;
    top: 70%;
    left: 50%;
    height: auto;
    z-index: -1;
    transform: translateX(-50%);
    transform: translateY(90%);

    width: 83.33vw;
    aspect-ratio: 300 / 48;
    height: auto;

    opacity: 0;
    animation: fadeIn 0.1s ease forwards;
}

.persona {
    display: flex;
    align-items: center;
    gap: 8px;
}

.positionBG {
    display: flex;
    justify-content: center;
    text-align: center;

    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;

    width: 5.58vw;
    aspect-ratio: 24 / 24;
    height: auto;
}

.positionBGCP {
    display: flex;
    justify-content: center;
    text-align: center;

    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;

    width: 20vw;
    aspect-ratio: 24 / 10;
    height: auto;
}

.goldPos {
    align-items: flex-start;
    background-image: url("/public/images/leaders/cup_gold.webp");
}

.positionBGCP.goldPos,
.positionBGCP.silverPos,
.positionBGCP.bronzePos {
    width: 8vw;
    aspect-ratio: 24 / 24;
}

.silverPos {
    align-items: flex-start;
    background-image: url("/public/images/leaders/cup_silver.webp");
}

.bronzePos {
    align-items: flex-start;
    background-image: url("/public/images/leaders/cup_bronze.webp");
}

.lowRatingPos {
    align-items: center;
    background-image: url("/public/images/leaders/reward_panel.webp");
}

.positionText {
    font-size: 0.75rem;
    color: var(--white);
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, 0 2px 0 #000, 1px 1px 0 #000;
}

.positionTextCP {
    font-size: 1rem;
    color: var(--white);
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, 0 2px 0 #000, 1px 1px 0 #000;
}

.avatarImg {
    width: 8.52vw;
    aspect-ratio: 32 / 32;
    height: auto;
    border-radius: 50%;
    border: 1px solid black;
}

.personaName {
    text-transform: uppercase;
    font-size: 1rem;
    color: var(--header-text);
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, 0 2px 0 #000, 1px 1px 0 #000;
}

.personaBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    flex-grow: 1;
}

.progressBox {
    display: flex;
    align-items: center;
    gap: 4px;
}

.rewardBox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 23.5vw;
    gap: 4px;
}

.rewardBoxCP {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26vw;
    gap: 4px;
}

.coinImg {
    width: 3.33vw;
    aspect-ratio: 12 / 12;
    height: auto;
}

.progressText {
    text-transform: uppercase;
    font-size: 0.75rem;
    color: var(--header-text);
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, 0 1px 0 #000, 1px 1px 0 #000;
}

.diamondImg {
    width: 5.56vw;
    aspect-ratio: 20 / 20;
    height: auto;
}

.rewardText {
    text-transform: uppercase;
    font-size: 0.7rem;
    color: var(--blue);
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, 0 1px 0 #000, 1px 1px 0 #000;
}

.rewardTextCP {
    text-transform: uppercase;
    font-size: 0.8rem;
    color: var(--blue);
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, 0 1px 0 #000, 1px 1px 0 #000;
}

.noRewardText {
    text-transform: uppercase;
    font-size: 1rem;
    color: var(--white);
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, 0 1px 0 #000, 1px 1px 0 #000;
}