.container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: space-between; */
    background-color: var(--green-grass-bg);
    height: 100vh;
    width: 100vw;
}

.btnClose {
    position: absolute;
    right: 5vw;
    top: 2.5vh;
    z-index: 3;
}

.sheepBox {
    position: relative;

    background: url("/public/images/about/about_bg.webp") no-repeat center;
    background-size: 100% 100%;

    width: 100vw;
    aspect-ratio: 360 / 196;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sheepImg {
    width: 33.89vw;
    aspect-ratio: 122 / 179;
    height: auto;
}

.welcomeImg {
    position: absolute;
    bottom: -9vh;
    z-index: 3;

    width: 75.56vw;
    aspect-ratio: 272 / 80;
    height: auto;
}

.textBox {
    margin-top: 9vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 75vw;
}

.text {
    font-size: 0.73rem;
    color: var(--header-text);
    /* font-family: "Lilita One"; */
    text-shadow: -1px -1px 0 #000,
        1px -1px 0 #000,
        0 2px 0 #000,
        1px 1px 0 #000;
}

.textLowercase {
    font-size: 0.55rem;
    color: var(--header-text);
    /* font-family: "Lilita One"; */
    text-shadow: -1px -1px 0 #000,
        1px -1px 0 #000,
        0 2px 0 #000,
        1px 1px 0 #000;
}

.isideContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2vh;
}

.versionBox {
    position: absolute;
    left: 48%;
    color: var(--header-text);
    font-size: 0.7rem;
    bottom: 1vh;
}

.totalUsers {
    position: absolute;
    left: 38%;
    color: var(--header-text);
    font-size: 0.7rem;
    bottom: 3vh;
}

/* ========= LIST ============= */
.list {
    display: flex;
    flex-direction: column;
    /* gap: 8px; */
    gap: 1.21vh;
    padding: 0 4.44vw;
    width: 91.11vw;
    overflow-y: auto;
}

.avaImg {
    margin-left: 4vw;
    width: 14.44vw;
    aspect-ratio: 52 / 62;
    height: auto;
}

.textContainer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-left: 4.44vw;
    gap: 1.21vh;
    padding-right: 2.5vw;
}

.goldPos {
    color: var(--about-gold)
}

.silverPos {
    color: var(--about-blue)
}

.bronzePos {
    color: var(--about-bronze)
}

.titleList {
    text-transform: uppercase;
    font-size: 1rem;
    text-shadow: -1px -1px 0 #000,
        1px -1px 0 #000,
        0 2px 0 #000,
        1px 1px 0 #000;
}

.ropesShortBG {
    position: absolute;
    top: -1.35vh;
    left: 50%;
    height: auto;
    transform: translateX(-50%);

    width: 41.11vw;
    aspect-ratio: 148 / 20;
    height: auto;
}

/* ======== FOOTER =========== */

.footer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 85vw;
}

@keyframes clickAnimation {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(0.9);
    }

    100% {
        transform: scale(1);
    }
}

.playBtn {
    position: relative;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    background-color: transparent;
    transition: transform 0.1s;
    background-image: url("/public/images/frens/invite_friends_btn.webp");

    width: 55vw;
    aspect-ratio: 198 / 48;
    height: auto;
    pointer-events: visible;
}

.playBtn:active {
    animation: clickAnimation 0.2s forwards;
}

.scissorsImg {
    position: absolute;
    top: -10%;
    left: 75%;
    transform: translateX(-75%);

    width: 11.11vw;
    aspect-ratio: 40 / 40;
    height: auto;
}

.textBtn {
    text-transform: uppercase;
    font-size: 1.25rem;
    color: var(--header-text);
    text-shadow: -1px -1px 0 #000,
        1px -1px 0 #000,
        0 2px 0 #000,
        1px 1px 0 #000;
}